import "./assets/scss/main.scss";
//import "bootstrap";

import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import * as Sentry from "@sentry/vue";
// Import the locale you want
import { enUS } from "date-fns/locale";
import "floating-vue/dist/style.css"
import FloatingVue from "floating-vue"
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css"

const app = createApp(App);

try {
  const sentryEnvironment = import.meta.env.VITE_SENTRY_ENVIRONMENT || "production";
  if (sentryEnvironment === "production") {
    Sentry.init({
      app,
      dsn: "https://5c113da3ba79b46b8e1570607c00952c@o4507045186437120.ingest.us.sentry.io/4507516359540736",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(
          {
            // NOTE: This will disable built-in masking. Only use this if your site has no sensitive data, or if you've already set up other options for masking or blocking relevant data, such as 'ignore', 'block', 'mask' and 'maskFn'.
            maskAllText: false,
            blockAllMedia: false,
          },
        ),
      ],
      environment: sentryEnvironment,
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/app\.nikateacher\.com\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      attachStacktrace: true,
    });
  }
} catch (error: any) {
  console.error("Sentry Init Error", error);
}

app.use(createPinia());
app.use(router);

// https://floating-vue.starpad.dev/guide/config
app.use(FloatingVue, {
  disposeTimeout: 3000,
  // Customize theme:
  // https://floating-vue.starpad.dev/guide/themes#custom-theme
  // https://floating-vue.starpad.dev/theme-editor
  themes: {
    tooltip: {
      delay: {
        show: 50,
        hide: 0,
      },
    },
  },
})
// VueDatePicker https://vue3datepicker.com/props/modes/
app.component("VueDatePicker", VueDatePicker);

// Set the locale for date-fns
// Provide the global locale using provide
app.provide("$dateLocale", enUS);

app.mount("#app");
